/* Navbar.css */

/* Navbar container */
.navbar {
    display: flex;
    justify-content: space-between; /* Space between left and right sections */
    align-items: center;
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    height: 60px; /* Adjust as needed */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

/* Left side of the navbar */
.navbar-left {
    display: flex;
    align-items: center;
}

/* Navbar menu */
.navbar-menu {
    display: flex;
    align-items: center;
    list-style: none;
}

/* Menu items */
.navbar-menu li {
    margin-right: 20px; /* Space between menu items */
}

/* Links */
.navbar-menu a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

/* Right side of the navbar */
.navbar-right {
    display: flex;
    align-items: center;
}

/* User avatar */
.user-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-right: 10px;
    text-transform: uppercase;
}

/* Logout link */
.logout-link {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

/* Hover effects */
.navbar-menu a:hover,
.logout-link:hover {
    text-decoration: underline;
}

.user-avatar:hover {
    opacity: 0.8;
}

/* Responsive styles */
@media (max-width: 768px) {
    .navbar {
        flex-wrap: wrap;
        height: auto;
        padding: 0 0;
    }

    .navbar-left,
    .navbar-right {
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }

    .navbar-menu {
        flex-direction: column;
        align-items: center;
    }

    .navbar-menu li {
        margin: 10px 0;
    }
}
