/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    min-height: 100vh;
}

/* Navbar Styles */
.navbar {
    width: 100%;
    height: 60px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: white;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.navbar .menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.navbar ul {
    list-style: none;
    display: flex;
    gap: 15px;
}

.navbar a {
    color: white;
    text-decoration: none;
}

.navbar a:hover {
    text-decoration: underline;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .navbar .menu-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #000;
        padding: 10px 0;
    }

    .navbar ul.open {
        display: flex;
    }
}

/* Progress bar Upload Status */
progress {
    width: 100%;
    height: 20px;
}

progress::-webkit-progress-bar {
    background-color: #f3f3f3;
}

progress::-webkit-progress-value {
    background-color: #4caf50;
}

progress::-moz-progress-bar {
    background-color: #4caf50;
}
