/* Dashboard.css */

.dashboard-container {
    display: flex;
    height: calc(100vh - 60px); /* Subtract the navbar height */
}

/* Sidebar styles */
.sidebar {
    width: 220px;
    background-color: #2c3e50;
    position: fixed;
    top: 60px; /* Start below the navbar */
    bottom: 0;
    overflow-y: auto;
}

.sidebar nav ul {
    list-style-type: none;
    padding: 20px 0;
}

.sidebar nav li {
    margin: 20px 0;
    text-align: center;
}

.sidebar nav a {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 18px;
    display: block;
    padding: 10px;
}

.sidebar nav a:hover {
    background-color: #34495e;
    border-radius: 4px;
}

/* Content area styles */
.dashboard-content {
    margin-left: 220px; /* Width of the sidebar */
    padding: 20px;
    overflow-y: auto;
    width: calc(100% - 220px);
}
